var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    [
      _c(
        "CCol",
        { attrs: { col: "" } },
        [
          _c(
            "CCard",
            [
              _c(
                "CCardHeader",
                [
                  _c("CIcon", { attrs: { name: "cil-justify-center" } }),
                  _c("strong", [_vm._v(" Bootstrap Breadcrumb")]),
                  _c("div", { staticClass: "card-header-actions" }, [
                    _c(
                      "a",
                      {
                        staticClass: "card-header-action",
                        attrs: {
                          href:
                            "https://coreui.io/vue/docs/components/breadcrumb",
                          rel: "noreferrer noopener",
                          target: "_blank"
                        }
                      },
                      [
                        _c("small", { staticClass: "text-muted" }, [
                          _vm._v("docs")
                        ])
                      ]
                    )
                  ])
                ],
                1
              ),
              _c(
                "CCardBody",
                [
                  _c("CBreadcrumb", { attrs: { items: _vm.items } }),
                  _c("CBreadcrumb", { attrs: { items: _vm.items2 } }),
                  _c("CBreadcrumb", { attrs: { items: _vm.items3 } })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }